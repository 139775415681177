<template>
  <!-- begin navbar -->
  <nav
    class="navbar navbar-expand-lg bg-white top-0 z-index-3 shadow position-fixed py-2 start-0 end-0"
  >
    <div class="container">
      <router-link to="/" class="navbar-brand text-primary">
        KOSTEL BANJARMASIN
      </router-link>
      <button
        class="navbar-toggler shadow-none ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon mt-2">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0" id="navigation">
        <ul class="navbar-nav navbar-nav-hover mx-auto">
          <li class="nav-item dropdown dropdown-hover mx-2">
            <router-link
              to="/kamar"
              class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
              aria-expanded="false"
            >
              Kamar
            </router-link>
          </li>
          <li class="nav-item dropdown dropdown-hover mx-2">
            <router-link
              to="/galeri"
              class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
              aria-expanded="false"
            >
              Galeri
            </router-link>
          </li>
          <li class="nav-item dropdown dropdown-hover mx-2">
            <router-link
              to="/cara-pemesanan"
              class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
              aria-expanded="false"
            >
              Cara Pemesanan
            </router-link>
          </li>
          <li class="nav-item dropdown dropdown-hover mx-2">
            <router-link
              to="/syarat-ketentuan"
              class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
              aria-expanded="false"
            >
              Syarat dan Ketentuan
            </router-link>
          </li>
          <li class="nav-item dropdown dropdown-hover mx-2">
            <router-link
              to="/tentang-kami"
              class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
              aria-expanded="false"
            >
              Tentang Kami
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-lg-block">
          <li class="nav-item dropdown dropdown-hover mx-2">
            <a
              role="button"
              class="btn btn-sm bg-gradient-primary btn-round mb-0 me-1"
              id="dropdownMenuDocs"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              > {{ storage_costumer != null ? 'AKUN' : 'MASUK' }} </a
            >
            <div
              class="dropdown-menu dropdown-menu-animation dropdown-lg mt-0 mt-lg-3 p-3 border-radius-lg"
              aria-labelledby="dropdownMenuDocs"
            >
              <div class="d-lg-block">
                <ul class="list-group">
                  <li
                    class="nav-item list-group-item border-0 p-0"
                    v-if="storage_costumer == null"
                  >
                    <router-link
                      to="/signin"
                      class="dropdown-item py-2 ps-3 border-radius-md"
                    >
                      <div class="d-flex">
                        <div class="icon h-10 me-3 d-flex mt-1">
                          <i class="fas fa-sign-in-alt text-secondary"></i>
                        </div>
                        <div>
                          <h6
                            class="dropdown-header text-dark font-weight-bold d-flex justify-content-cente align-items-center p-0"
                          >
                            Masuk
                          </h6>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li
                    class="nav-item list-group-item border-0 p-0"
                    v-if="storage_costumer == null"
                  >
                    <router-link
                      to="/signup"
                      class="dropdown-item py-2 ps-3 border-radius-md"
                    >
                      <div class="d-flex">
                        <div class="icon h-10 me-3 d-flex mt-1">
                          <i class="fas fa-user-plus text-secondary"></i>
                        </div>
                        <div>
                          <h6
                            class="dropdown-header text-dark font-weight-bold d-flex justify-content-cente align-items-center p-0"
                          >
                            Daftar
                          </h6>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li
                    class="nav-item list-group-item border-0 p-0"
                    v-if="storage_costumer != null"
                  >
                    <div @click="logout" class="dropdown-item py-2 ps-3 border-radius-md">
                      <div class="d-flex">
                        <div class="icon h-10 me-3 d-flex mt-1">
                          <i class="fas fa-user-plus text-secondary"></i>
                        </div>
                        <div>
                          <h6
                            class="dropdown-header text-dark font-weight-bold d-flex justify-content-cente align-items-center p-0"
                          >
                            Keluar
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="nav-item list-group-item border-0 p-0"
                    v-if="storage_costumer != null"
                  >
                    <router-link
                      to="/profil-dashboard"
                      class="dropdown-item py-2 ps-3 border-radius-md"
                      href="pages/profile/profile.html"
                    >
                      <div class="d-flex">
                        <div class="icon h-10 me-3 d-flex mt-1">
                          <i class="fas fa-id-card text-secondary"></i>
                        </div>
                        <div>
                          <h6
                            class="dropdown-header text-dark font-weight-bold d-flex justify-content-cente align-items-center p-0"
                          >
                            Profile
                          </h6>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- end navbar -->
</template>
<script>
export default {
  name: "navbar",
  props: {
    storage_costumer: Object,
  },
  methods: {
    logout() {
      auth.logout();
    }
  }
};
</script>
