class helperUmum {

    urlPublic() {
        // untuk develop
        // return "http://kostel.bekantanjantan.com/backend"

        // untuk serve
        return `${window.location.origin}/backend`
    }

    genereteColorRandom() {
        let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        return randomColor
    }

    zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    convertTanggalWaktuFormatIndonesia(date) {
        var date = new Date(date);
        var tahun = date.getFullYear();
        var bulan = date.getMonth();
        var tanggal = date.getDate();
        var hari = date.getDay();
        var jam = date.getHours();
        var menit = date.getMinutes();
        var detik = date.getSeconds();

        switch (hari) {
            case 0:
                hari = "Minggu";
                break;
            case 1:
                hari = "Senin";
                break;
            case 2:
                hari = "Selasa";
                break;
            case 3:
                hari = "Rabu";
                break;
            case 4:
                hari = "Kamis";
                break;
            case 5:
                hari = "Jum'at";
                break;
            case 6:
                hari = "Sabtu";
                break;
        }
        switch (bulan) {
            case 0:
                bulan = "Januari";
                break;
            case 1:
                bulan = "Februari";
                break;
            case 2:
                bulan = "Maret";
                break;
            case 3:
                bulan = "April";
                break;
            case 4:
                bulan = "Mei";
                break;
            case 5:
                bulan = "Juni";
                break;
            case 6:
                bulan = "Juli";
                break;
            case 7:
                bulan = "Agustus";
                break;
            case 8:
                bulan = "September";
                break;
            case 9:
                bulan = "Oktober";
                break;
            case 10:
                bulan = "November";
                break;
            case 11:
                bulan = "Desember";
                break;
        }

        var tampilTanggal = tanggal + " " + bulan + " " + tahun;
        var tampilWaktu = "Jam: " + jam + ":" + menit + ":" + detik;

        return { tanggal: tampilTanggal, waktu: tampilWaktu }
    }

    convertTanggalFormatIndonesia(date) {

        var date = new Date(date);
        var tahun = date.getFullYear();
        var bulan = date.getMonth();
        var tanggal = date.getDate();
        var hari = date.getDay();
        var jam = date.getHours();
        var menit = date.getMinutes();
        var detik = date.getSeconds();

        switch (hari) {
            case 0:
                hari = "Minggu";
                break;
            case 1:
                hari = "Senin";
                break;
            case 2:
                hari = "Selasa";
                break;
            case 3:
                hari = "Rabu";
                break;
            case 4:
                hari = "Kamis";
                break;
            case 5:
                hari = "Jum'at";
                break;
            case 6:
                hari = "Sabtu";
                break;
        }
        switch (bulan) {
            case 0:
                bulan = "Januari";
                break;
            case 1:
                bulan = "Februari";
                break;
            case 2:
                bulan = "Maret";
                break;
            case 3:
                bulan = "April";
                break;
            case 4:
                bulan = "Mei";
                break;
            case 5:
                bulan = "Juni";
                break;
            case 6:
                bulan = "Juli";
                break;
            case 7:
                bulan = "Agustus";
                break;
            case 8:
                bulan = "September";
                break;
            case 9:
                bulan = "Oktober";
                break;
            case 10:
                bulan = "November";
                break;
            case 11:
                bulan = "Desember";
                break;
        }

        var tampilTanggal = tanggal + " " + bulan + " " + tahun;
        var tampilWaktu = "Jam: " + jam + ":" + menit + ":" + detik;

        return tampilTanggal
    }

    seoJudulSlug(text) {
        const arrayRegex = [
            ".",
            "-",
            "/",
            "\\",
            ",",
            "#",
            ":",
            ";",
            "'",
            '"',
            "[",
            "]",
            "{",
            "}",
            ")",
            "(",
            "|",
            "`",
            "~",
            "!",
            "@",
            "%",
            "$",
            "^",
            "&",
            "*",
            "=",
            "?",
            "+",
        ];
        for (let index = 0; index < arrayRegex.length; index++) {
            text = text.trim().replaceAll(arrayRegex[index], " "); // Hilangkan karakter yang telah disebutkan di array $d
        }
        text = text.replaceAll(" ", "-"); // Ganti spasi dengan tanda - dan ubah hurufnya menjadi kecil semua
        text = text.toLowerCase();
        return text;
    }

    urlMultipleUpload() {
        return `${process.env.VUE_APP_URL}/api/v1/media/upload-multi-media`
    }

    testHelper() {
        return "helper"
    }

    PageTitle_iconPemberitahuan() {
        return "lnr-bullhorn text-danger"
    }

    PageTitle_subheadingTable(namaTable) {
        return `Ini adalah tabel yang menampilkan semua data ${namaTable}.
        Pada simbol lingkaran, Anda dapat melakukan mengurutkan data (order), memfilter data, mengurangi atau menambah judul kolom, menentukan jumlah data yang ditampilkan dan tampilan tabel.
        Pada simbol dengan 3 garis, Anda dapat melakukan hapus, buat data baru, merubah data dan download data. 
        Anda dapat memilih data dengan cara klik langsung pada tabel data yang ada pilih.`
    }

    PageTitle_subheadingCreate() {
        return "Isilah data formulir dibawah ini dengan baik dan benar. Warna merah menandakan bahwa kolom pengisian tersebut wajib di isi. Anda bisa menambahkan banyak data yang dimasukan dengan menambah kolom pengisian di bagian Tambah Inputan. Dan menghapus data kolom pengisian pada tombol merah dengan icon silang."
    }

    PageTitle_subheadingUpdate() {
        return "Isilah data formulir dibawah ini dengan baik dan benar. Warna merah menandakan bahwa kolom pengisian tersebut wajib di isi."
    }


    acl() {
        // bekas test acl static
        let acl = {

        }
        return acl
    }

    // begin imge error 404
    imgError(event) {
        // event.target.src = require("@/assets/images/no-user-image.jpeg");
    }
    // end imge error 404

    getMedia(urlMedia) {
        if (urlMedia != undefined) {
            if (urlMedia.slice(0, 3) == 'med') {
                return axios({
                    method: "post",
                    url: `${process.env.VUE_APP_URL}/api/v1/${urlMedia}/stream`,
                    // url: `${urlMedia}/stream`,
                    headers: {
                        "Content-type": "image/jpeg",
                    },
                    responseType: "blob",
                }).then(response => window.URL.createObjectURL(new Blob([response.data])))
            } else {
                return axios({
                    method: "post",
                    // url: `${process.env.VUE_APP_URL}/api/v1/${urlMedia}/stream`,
                    url: `${urlMedia}/stream`,
                    headers: {
                        "Content-type": "image/jpeg",
                    },
                    responseType: "blob",
                }).then(response => window.URL.createObjectURL(new Blob([response.data])))
            }
        }

    }

    downloadMedia(mediaUrlDownload, namaMedia, extensi, passwordStatus, logRouteBefore) {
        // jika passwordStatus == true maka munculkan input password
        // let logRouteBefore = this.logRouteBefore.fullPath;

        if (passwordStatus) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    title: "mt-3 text-secondary",
                },
            });

            swalWithBootstrapButtons.fire({
                title: "Masukan Password Media",
                html: `<input type="password" id="password" class="swal2-input" placeholder="Password">`,
                confirmButtonText: "Konfirmasi",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const password = Swal.getPopup().querySelector("#password").value;
                    if (password) {
                        return axios({
                            url: mediaUrlDownload + "/download",
                            method: "POST",
                            responseType: "blob",
                            data: {
                                media_password: password,
                            },
                            headers: {
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                datalog: logRouteBefore,
                            },
                        })
                            .then((response) => {
                                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                                var fileLink = document.createElement("a");

                                fileLink.href = fileURL;
                                fileLink.setAttribute("download", `${namaMedia}.${extensi}`);
                                document.body.appendChild(fileLink);

                                fileLink.click();
                            })
                            .catch((error) => {
                                Swal.showValidationMessage(`Gagal: silahkan coba lagi.`);
                            });
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        } else {
            // jika passwordStatus == false maka bisa langsung download
            axios({
                url: mediaUrlDownload + "/download",
                method: "POST",
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    datalog: logRouteBefore,
                },
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", `${namaMedia}.${extensi}`);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }

    convert_size(size, decimal = 2) {
        string = null;
        if (size >= 1073741824) {
            string = this.funcFormatCurency(size / 1073741824, decimal) + ' GB';
        } else if (size >= 1048576) {
            string = this.funcFormatCurency(ize / 1048576, decimal) + ' MB';
        } else if (size >= 1024) {
            string = this.funcFormatCurency(size / 1024, decimal) + ' KB';
        } else if (size > 1) {
            string = size + ' Bytes'
        } else {
            string = 0 + ' Byte';
        }

        return string;
    }

    convertToRoman(num) {
        var v = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        var r = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
        var s = "";
        for (let i = 0; i < v.length; i++) {
            let value = parseInt(num / v[i]);
            for (let j = 0; j < value; j++) {
                s += r[i];
            }
            num = num % v[i];
        }
        return s;
    }

    funcFormatCurency(value) {
        if (typeof value == "string" && value != undefined && value != "NaN") {
            const valueNumber = value.match(/\d+/g);
            const format = valueNumber[0].toString().split("").reverse().join("");
            const convert = format.match(/\d{1,3}/g);
            const FormatCurency = convert.join(".").split("").reverse().join("");
            let FormatCurencyDecimal = 0;
            if (valueNumber.length > 1) {
                // artinya ada decimalnya
                FormatCurencyDecimal = `${FormatCurency},${valueNumber[1]}`;
            } else {
                FormatCurencyDecimal = FormatCurency;
            }
            return FormatCurencyDecimal;
        } else if (typeof value == "number") {
            const format = value.toString().split("").reverse().join("");
            const format2 = format.split(".");

            let convert = 0;
            if (format2.length > 1) {
                // artinya ada decimalnya
                convert = format2[1].match(/\d{1,3}/g);
            } else {
                convert = format2[0].match(/\d{1,3}/g);
            }
            const FormatCurency = convert.join(".").split("").reverse().join("");
            let FormatCurencyDecimal = 0;
            if (format2.length > 1) {
                // artinya ada decimalnya
                const decimalDigit = format2[0]
                    .match(/\d{1,3}/g)
                    .join(".")
                    .split("")
                    .reverse()
                    .join("");
                FormatCurencyDecimal = `${FormatCurency},${decimalDigit}`;
            } else {
                FormatCurencyDecimal = FormatCurency;
            }

            if (value < 0) {
                const minusFormatCurency = `-${FormatCurency}`;
                return minusFormatCurency;
            } else {
                return FormatCurencyDecimal;
            }
        } else {
            return value;
        }
    }

}



export default helperUmum = new helperUmum();
