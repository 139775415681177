import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/section/homepage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "singin-page" */ '../views/section/singin-page.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "singup-page" */ '../views/section/singup-page.vue')
  },
  {
    path: '/syarat-ketentuan',
    name: 'syarat-ketentuan',
    component: () => import(/* webpackChunkName: "sk" */ '../views/section/syarat-ketentuan-page.vue')
  },
  {
    path: '/cara-pemesanan',
    name: 'cara-pemesanan',
    component: () => import(/* webpackChunkName: "carapemesanan" */ '../views/section/cara-pemesanan.vue')
  },
  {
    path: '/tentang-kami',
    name: 'tentang-kami',
    component: () => import(/* webpackChunkName: "tentangKami" */ '../views/section/tentang-kami.vue')
  },
  {
    path: '/galeri',
    name: 'galeri',
    component: () => import(/* webpackChunkName: "galeri" */ '../views/section/galeri.vue')
  },
  {
    path: '/kamar',
    name: 'kamar',
    component: () => import(/* webpackChunkName: "kamar" */ '../views/section/kamar.vue')
  },
  {
    path: '/available-kamar/:start_date/:jenis_menginap/:kuantitas_menginap',
    name: 'available-kamar',
    component: () => import(/* webpackChunkName: "availabelKamarPage" */ '../views/section/available-kamar-page.vue')
  },
  {
    path: '/profil-dashboard',
    name: 'profil-dashboard',
    component: () => import(/* webpackChunkName: "profil-dashboard" */ '../views/section/profile-dashboard-page.vue')
  },
  { path: "*", component: () => import(/* webpackChunkName: "notFound" */ '../views/section/page-not-found.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
